<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div
          class="page_con"
          v-on="
            pointer
              ? {
                  pointerdown: $event => start($event),
                  pointermove: $event => move($event),
                  pointerup: $event => end($event)
                }
              : {
                  touchstart: $event => start($event),
                  touchmove: $event => move($event),
                  touchend: $event => end($event)
                }
          "
        >
          <div class="lump">
            <label class="input srh_type02">
              <input
                type="search"
                placeholder="검색어를 입력해주세요."
                v-model="text"
                @keyup.enter="fnSearch"
              />
              <button class="btn_search" @click="fnSearch">검색</button>
            </label>
          </div>
          <div class="party_list_wrap">
            <div class="list_top">
              <div class="left">
                <button class="total">
                  총 {{ $Util.formatNumber2(totalCnt, { unit: '' }) }}건
                </button>
              </div>
              <div class="right">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    :true-value="'Y'"
                    :false-value="'N'"
                    :checked="endYn === 'Y'"
                    v-model="endYn"
                  />
                  <span class="label"><span>종료 비노출</span></span>
                </label>
                <label class="select">
                  <select v-model="sort">
                    <option :value="'REGDT'">신규등록순</option>
                    <option :value="'SHORT'">짧은기간순</option>
                    <option :value="'LONG'">긴기간순</option>
                    <option :value="'MODDT'">업데이트순</option>
                  </select>
                </label>
              </div>
            </div>
            <div class="party_list row_type">
              <!--'가로형: row_type'-->
              <ul class="party_list_ul">
                <li
                  class="party_list_li"
                  :class="{ disabled: $dayjs(item.notiDt).diff($dayjs()) < 0 }"
                  v-for="(item, index) in list"
                  :key="index"
                  @click="fnModalAlertPartyHope(item)"
                >
                  <div class="party_tag">
                    <div class="left">
                      <div
                        class="pt_tag logo"
                        style="--pt-txt-color: #fff;"
                        :style="{
                          '--pt-bg-color': $ConstCode.getCategoryById(
                            item.categoryId
                          ).color
                        }"
                      >
                        {{ item.categoryNm }}
                      </div>
                    </div>
                    <div class="right">
                      <div
                        class="pt_tag"
                        :class="[
                          {
                            end_txt: $dayjs(item.notiDt).diff($dayjs()) < 0
                          },
                          {
                            new: item.newBadge === 'Y'
                          }
                        ]"
                      >
                        {{
                          $dayjs(item.notiDt).diff($dayjs()) > 0 ? '' : '종료'
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="party_ttl">{{ item.title }}</div>
                  <div class="party_con">
                    <div class="left">
                      <div class="party_info">
                        <dl>
                          <dt>기간 :</dt>
                          {{ `${item.usePeriod}일`
                          }}{{
                            `(최소 일 평균 ${(
                              Number(item.minPrice) / Number(item.usePeriod)
                            ).toFixed(0)}원)`
                          }}
                        </dl>
                      </div>
                    </div>
                    <div class="right">
                      <div class="party_price">
                        <span class="value">{{
                          `${$Util.formatNumber2(item.minPrice, {
                            unit: ''
                          })} ~ ${$Util.formatNumber2(item.maxPrice, {
                            unit: ''
                          })}`
                        }}</span
                        ><!--띄어쓰기-->
                        <span class="unit">원</span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <observe-visible
                :key="initCnt"
                :page="page"
                :limit="limit"
                :totalCnt="totalCnt"
                @fetch="fnListFunction"
              />
            </div>
            <div class="con_btn" v-if="!$Util.isEmpty(userData)">
              <!--              <button class="btn bo">리스트 더보기</button>-->
              <button class="btn web_break" @click="fnWrite">
                파티 생성하기</button
              ><!--버튼 중복: 웹 분기점 디자인 적용-->
            </div>
          </div>
        </div>
        <div class="page_btn" v-if="!$Util.isEmpty(userData)">
          <div class="btn_wrap">
            <button class="btn" @click="fnWrite">파티 생성하기</button
            ><!--버튼 중복: 웹 분기점 디자인 적용-->
          </div>
        </div>
      </div>
    </div>
    <nice
      :id="'formNice9'"
      :m="niceForm.m"
      :encode-data="niceForm.encodeData"
      :call-type="niceForm.callType"
      :rtn-url="rtnUrl"
      :fail-rtn-url="failRtnUrl"
      :callback="fnCallback"
    />
  </main>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'community-party-hope-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
