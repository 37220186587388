import { reactive, toRefs, getCurrentInstance, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { fnModalAlert } from '@/components/common/modal/alert'
import { CommonFunction } from '@/common/CommonFunction'
import { niceForm } from '@/common/form/NiceForm'

const INIT_PAGE = 0
const INIT_LIMIT = 6
const INIT_TOTAL_CNT = 0

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const { getters } = useStore()
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    page: INIT_PAGE,
    limit: INIT_LIMIT,
    totalCnt: INIT_TOTAL_CNT,
    endYn: route.query.endYn || 'Y',
    text: route.query.search || '',
    search: route.query.search || '',
    sort: route.query.sort || 'MODDT',
    params: computed({
      get: () => {
        return {
          page: state.page + 1,
          search: state.search,
          endYn: state.endYn,
          sort: state.sort
        }
      }
    }),
    list: [],
    initCnt: 0,
    niceForm: { ...niceForm },
    rtnUrl: `${window.location.origin}/my-info/success`,
    failRtnUrl: `${window.location.origin}/my-info/fail`,
    pointer: computed(() => (window.PointerEvent ? true : false)),
    activeTab: 2,
    touch: {}
  })
  const {
    fnWrite,
    fnUpdateAuthType,
    switchTab,
    start,
    move,
    end,
    setTouchPos
  } = CommonFunction(state)
  const fnListFunction = async () => {
    const res = await proxy.$PartySvc.postPartyHopeList(state.params)
    if (res.resultCode === '0000') {
      state.page++
      state.totalCnt = res.count
      state.list = state.list.concat(res.list)
    }
  }
  const fnSearch = async () => {
    await router.replace({
      path: '/community/party-hope',
      query: { search: state.text, endYn: state.endYn, sort: state.sort }
    })
  }
  const fnModalAlertPartyHope = async item => {
    const params = {}
    params.seq = item.seq
    const res = await proxy.$PartySvc.postPartyPartyHopeDetail(params)
    const payload = {
      component: proxy.$modalAlertNames.PARTY_HOPE,
      data: res.detail,
      callback: async () => {}
    }
    await fnModalAlert(payload)
  }
  const fnCallback = async params => {
    if (!proxy.$Util.isEmpty(params)) {
      if (
        params.callType === proxy.$ConstCode.NICE_CALL_TYPE.INIT_SNS_LOGIN.value
      ) {
        fnUpdateAuthType()
      }
      await router.push({ path: '/my-info/phone-auth' })
      // await fnMyInfo()
    } else {
      alert('본인인증에 실패하였습니다.')
    }
  }
  /** watch **/
  watch(
    () => state.endYn,
    async () => {
      await fnSearch()
    }
  )
  watch(
    () => state.sort,
    async () => {
      await fnSearch()
    }
  )
  return {
    ...toRefs(state),
    fnListFunction,
    fnSearch,
    fnModalAlertPartyHope,
    fnWrite,
    fnCallback,
    switchTab,
    start,
    move,
    end,
    setTouchPos
  }
}
